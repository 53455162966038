import axios from "axios";

export default {
    async getProdottiByCommessa(commessaId: string) {
        const data = new FormData();
        //data.append("where[]", `fw_products_id IN (select fw_products_id from rel_project_prod where projects_id = '${commessaId}')`);
        data.append("where[]", `fw_products_id IN (SELECT project_products_product FROM project_products WHERE project_products_project = '${commessaId}')`);

        //devo cambiare chiamata, non farla su fw_products ma sull'entità nuova quaindi project_products_product con il where sulla commessa visualizata

        const response = await axios.post(`rest/v1/search/fw_products`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data.data);
        return response.data;
    },

    async getProdotti(commessaId: string) {
        const data = new FormData();
        //data.append("where[]", `fw_products_id IN (select fw_products_id from rel_project_prod where projects_id = '${commessaId}')`);
        data.append("where[]", `project_products_project = '${commessaId}'`);

        //devo cambiare chiamata, non farla su fw_products ma sull'entità nuova quaindi project_products_product con il where sulla commessa visualizata

        const response = await axios.post(`rest/v1/search/project_products`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data.data);
        return response.data;
    },

    /*
    VERSIONE PRIMA DI QUELLA QUI SOPRA CHE ERA STATA MESSA NELL'API CUSTOM
    async getProdotti() {
        const data = new FormData();
        data.append("where[fw_products_kind]", "1");
        data.append("orderby", "fw_products_name");
        data.append("orderdir", "asc");

        const response = await axios.post("rest/v1/search/fw_products", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data.data);
        return response.data;
    },*/

    async saveOrdineMateriale(data) {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}projects/documenti/express_save`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response;
    },

    async getProductDocument(prod: string) {
        const data = new FormData();
        data.append("where[]", `fw_product_documents_product = '${prod}'`);

        const response = await axios.post(`rest/v1/search/fw_product_documents`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data.data);
        return response.data;
    },
};
