import axios from "axios";

import { setCustomers } from "./localbase";

export default {
  async getCustomers() {
    const data = new FormData();
    //data.append("limit", "300");
    data.append("offset", "0");
    data.append("where[customers_type]", "1");
    data.append("orderby", "customers_company");
    data.append("orderdir", "asc");

    const response = await axios.post("rest/v1/search/customers", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //qui salvo in indexeddb la collection Clienti nel db Clienti
    // con chiave id cliente e valore il cliente
    /*         response.data.data.forEach(cliente => {
            setCustomers(cliente);
        }); */
    //setCustomers(response.data.data);
    //localStorage.setItem('clienti', JSON.stringify(response.data.data));
    //console.log(response);
    return response.data;
  },

  async getCustomersLead() {
    const data = new FormData();
    //data.append("limit", "300");
    data.append("offset", "0");
    data.append("where[customers_type]", "4");
    data.append("orderby", "customers_company");
    data.append("orderdir", "asc");

    const response = await axios.post("rest/v1/search/customers", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //qui salvo in indexeddb la collection Clienti nel db Clienti
    // con chiave id cliente e valore il cliente
    /*         response.data.data.forEach(cliente => {
            setCustomers(cliente);
        }); */
    //setCustomers(response.data.data);
    //localStorage.setItem('clienti', JSON.stringify(response.data.data));
    //console.log(response);
    return response.data;
  },

  async getSediCliente(clienteId: string) {
    const data = new FormData();
    data.append("where[customers_shipping_address_customer_id]", clienteId);

    const response = await axios.post("rest/v1/search/customers_shipping_address", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response.data;
  },
};
